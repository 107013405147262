import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_EN } from "./translations/en/en";
import { TRANSLATIONS_RU } from "./translations/ru/ru";
import { TRANSLATIONS_ES } from "./translations/es/es";

export const languages = ["en-US", "ru-RU", "es-ES"];

i18n.use(initReactI18next).init({
  fallbackLng: "en-US",
  resources: {
    "en-US": {
      translation: TRANSLATIONS_EN,
    },
    "ru-RU": {
      translation: TRANSLATIONS_RU,
    },
    "es-ES": {
      translation: TRANSLATIONS_ES,
    },
  },
});

if (localStorage.getItem("i18nextLng") && languages.some((i) => i === localStorage.getItem("i18nextLng"))) {
  i18n.changeLanguage(localStorage.getItem("i18nextLng"));
} else {
  localStorage.setItem("i18nextLng", languages.at(0));
  i18n.changeLanguage(languages.at(0));
}

export default i18n;
