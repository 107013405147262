export const TRANSLATIONS_RU = {
    navBar: {
        lang: {
            "en-US": "Английский",
            "ru-RU": "Русский",
            "es-ES": "Испанский",
        }
    },

    banner: {
        title: "за подписку",
        desc: "Просто и безопасно! Чтобы получить BNB, достаточно подписаться на телеграмм канал и быстро и легко подключить свой крипто кошелек. Это абсолютно безопасно и занимает всего пару минут! Только так мы сможем отправить вам ваш выигрыш! Присоединяйся и получи свой бонус без лишних хлопот.",
        subtitle: "Успейте получить токены!",
        connection: "Подключение...",
        btnMeta: "Подключить Metamask",
        btnTron: "Подключить Tron",
        btnTronLink: "Подключить TronLink",
        btnTelegram: "Подписаться на канал",
        wallet: "Кошелек подключен:",
        walletTitle: "Подключить кошелек:",
        disconnect: "Отключить кошелек",
        info: "Ожидайте токен в течении: 60 мин.",
        target: 'Цель',
        token: "Токенов",
        error: {
            tronInfo: "Пожалуйста, подключите ваш кошелек",
            tronLink: "Ошибка подключения TronLink:",
            tronLinkInstall: "TronLink не установлен",
            token: "Кошелек не найден",
            trc20: "Не удалось подтвердить токен TRC20",
        },
    },

    footer: {
        desc: "Подключи свой кошелек и получай токены первым! После подключения кошелька ожидайте новостей в официальный источниках. Успехов!",
        copy: 'Copyright © 2024 Gittu. Все права защищены.',
    }
};
