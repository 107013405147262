import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <section className="footer">
      <div className="container">
        <Row>
          <Col lg={12} md={12} sm={12} className="text-left">
            <img
              src={require("../assets/img/logo.png")}
              className="logo"
              alt=""
            />
            <p>{t(`${basePath}desc`)}</p>
          </Col>
        </Row>
        <div className="copy">{t(`${basePath}copy`)}</div>
      </div>
    </section>
  );
};

export default Footer;
